<template>
<div class="main">
  <span>404 - Not Found</span>
</div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>